import type { RouteLocationRaw } from 'vue-router'
import type { Review } from '~/codegen/graphql'
import type { Artifact } from '~/components/artifact/IndexPage.vue'
import { capitalize as capitalizeFn } from 'remeda'
import { ProviderCategory, ReviewType, Score, SearchResultType } from '~/codegen/graphql'
import { RepeatArtifactDocument } from './artifact.generated'

export const REVIEW_FETCHING_STATE_KEY = 'review:fetching'
export const REVIEW_FETCHING_FAILED_STATE_KEY = 'review:fetching:failed'

export function getScoreColor(score: Score) {
  return {
    [Score.Bad]: 'bg-red-500',
    [Score.Ok]: 'bg-yellow-500',
    [Score.Good]: 'bg-green-500',
  }[score]
}

export function getArtifactTitleWithDate(artifact: Pick<Review, 'name' | 'end'>) {
  if (!artifact)
    return ''
  return `${artifact.name} (${toShortDate(artifact.end)})`
}

export async function repeatArtifactAndNavigateToIt(workspaceId: string, id: string, type: ReviewType) {
  const { execute: repeatArtifact } = useMutation(
    RepeatArtifactDocument,
    { clearCacheTags: [`workspace-${workspaceId}-${getArtifactName(type)}s`] },
  )

  const response = await repeatArtifact({ input: { fromReview: id } })
  const newId = response.data?.repeatArtifact?.review?.id

  if (newId) {
    navigateTo(getRouteForArtifact(newId, workspaceId, type))
  }

  handleMutationError({ errors: response.data?.repeatArtifact?.errors, combinedError: response.error })
}

export function getArtifactName(type: ReviewType | null, settings?: { capitalize?: boolean, plural?: boolean }): string {
  if (type === null)
    return ''

  let label = type.toLowerCase()

  if (settings?.capitalize)
    label = capitalizeFn(label)

  if (settings?.plural)
    label += 's'

  return label
}

export function getAlreadySentWarning(type: ReviewType, action: string): string {
  return `Survey has already been sent. To ${action}, move the ${getArtifactName(type)} back to draft first.`
}

export function getDefaultScopeWarning(context: 'survey-sending' | 'widget-scoping'): string {
  const action = context === 'widget-scoping'
    ? 'the data in this widget will be scoped'
    : 'this survey will be sent'

  return `You haven’t selected any teams or users, so ${action} to all the members of the current teamspace.`
}

export function containsSurveyWidgets(artifact: Artifact) {
  return artifact.sections.edges.some(({ node }) =>
    node.widgets.edges.some(({ node }) => node.widgetType.isSurveyQuestionWidget))
}

export type AssessmentScoreBadgeColor = 'green' | 'yellow' | 'red' | 'gray'
export const assessmentScoreOptions: Record<string, { label: string | null, color: AssessmentScoreBadgeColor }> = {
  [Score.Good]: { label: 'Good', color: 'green' },
  [Score.Ok]: { label: 'OK', color: 'yellow' },
  [Score.Bad]: { label: 'At risk', color: 'red' },
  blank: { label: null, color: 'gray' },
}

export function getMetadataForScore(score: Score | null | undefined) {
  return assessmentScoreOptions[score ?? 'blank']
}

export function getRouteForArtifact(
  artifactId: string,
  workspaceId: string,
  type: ReviewType,
): RouteLocationRaw {
  return {
    [ReviewType.Review]: {
      name: 'teamspace-workspaceId-reviews-reviewId' as const,
      params: { workspaceId, reviewId: artifactId },
    },
    [ReviewType.Survey]: {
      name: 'teamspace-workspaceId-surveys-surveyId' as const,
      params: { workspaceId, surveyId: artifactId },
    },
    [ReviewType.Dashboard]: {
      name: 'teamspace-workspaceId-dashboards-dashboardId' as const,
      params: { workspaceId, dashboardId: artifactId },
    },
  }[type]
}

export function searchResultTypeToReviewType(
  searchResultType: Exclude<SearchResultType, SearchResultType.Workspace>,
) {
  return {
    [SearchResultType.Dashboard]: ReviewType.Dashboard,
    [SearchResultType.Review]: ReviewType.Review,
    [SearchResultType.Survey]: ReviewType.Survey,
  }[searchResultType]
}

export const objectTypeIconMapping = {
  [SearchResultType.Review]: {
    bigIcon: 'duotone-solid:file-lines',
    smallIcon: 'regular:file',
    color: 'text-blue-400',
  },
  [SearchResultType.Survey]: {
    bigIcon: 'duotone-solid:clipboard-check',
    smallIcon: 'regular:clipboard-check',
    color: 'text-purple-400',
  },
  [SearchResultType.Dashboard]: {
    bigIcon: 'duotone-solid:square-poll-vertical',
    smallIcon: 'regular:square-poll-vertical',
    color: 'text-green-400',
  },
  [SearchResultType.Workspace]: {
    bigIcon: '',
    smallIcon: '',
    color: '',
  },
}

export function requiredProviderCategories(type: ReviewType) {
  return type === ReviewType.Review
    ? [ProviderCategory.Code, ProviderCategory.Metric, ProviderCategory.Issue]
    : [ProviderCategory.Chat]
}
